.form_auth_main {
  box-shadow: rgb(0 0 0 / 15%) 0px 1px 5px;
  padding: 5px;
  width: 60%;
  margin: 0 auto;
}

.auth_error_success {
  font-size: 14px;
}

.auth_verify_text {
  font-size: 17px;
}

.auth_member_img {
  width: 110px;
  border-radius: 100px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.auth_link {
  color: #06b6d4;
  cursor: pointer;
}
.auth_link:hover {
  color: #1890ff;
}

.auth_label {
  font-size: 17px;
  margin: 10px 0;
}

.auth_input {
  font-size: 17px;
  padding: 10px 0px;
}

.auth_btn_reg {
  font-size: 20px !important;
  height: 47px !important;
  border-radius: 50px;
  background-color: #38d39f !important;
  border-color: #38d39f !important;
  width: 100%;
  color: #fff !important;
}
.auth_btn_reg:hover {
  background-color: #1890ff !important;
  border-color: #1890ff !important;
}

.otp_gen_btn {
  font-size: 14px !important;
  background-color: #38d39f !important;
  border-color: #38d39f !important;
}

.upload_img_btn {
  padding: 10px;
  border-radius: 3px;
  cursor: pointer;
}

.upload_dis_img_btn {
  background-color: #ddd;
  padding: 10px;
  border-radius: 3px;
  cursor: none;
}

.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-container > input {
  margin: 10px;
  padding: 3%;
  font-size: 22px;
  text-align: center;
}

.vcode_input {
  font-size: 22px !important;
  background-image: none !important;
}

.vcode_input::placeholder {
  font: 22px/3 sans-serif;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

/*  */

.formInput {
  display: flex;
  flex-direction: column;
}

.error_form {
  font-size: 13px;
  color: red;
  display: none;
  margin-top: 3px;
}

/* input:invalid[focused='true'] {
  border: 1px solid red;
} */

input:invalid[focused='true'] ~ span {
  display: block;
}
