.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: rgba(216, 73, 73, 0.2); */
  width: 100%;
  font-weight: 500;
  font-size: 15px;
}

.pagination-container button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  height: 40px;
  width: 40px;
  text-decoration: none;
  transition: background-color 0.2s;
  border: 1px solid #ddd;
  cursor: pointer;
}

.pagination-container button.active {
  background-color: rgb(0, 168, 206);
  color: white;
  border: 1px solid #24a6e2;
}

.pagination-container button.disabled {
  opacity: 0.2;
}
.pagination-container button:hover:not(.active) {
  background-color: rgb(238, 238, 238);
}
