.home_nav {
  border-bottom: none !important;
}
.home_nav_icon svg {
  margin-top: -7px;
}
.home_nav_main {
  padding-bottom: 15px !important;
}
.home_nav_main a {
  font-size: 14px;
}
