.submit_btn {
  font-size: 18px !important;
  line-height: 0.5;
  border-radius: 50px;
  background-color: #38d39f !important;
  border-color: #38d39f !important;
  min-width: 150px;
  height: 35px !important;
  color: #fff !important;
}
.submit_btn:hover {
  background-color: #1890ff !important;
  border-color: #1890ff !important;
}
