@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin-top: 12px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.blogSwiper .swiper-button-next,
.blogSwiper .swiper-button-prev {
  background-color: white;
  background-color: rgba(255, 255, 255, 0.5);
  right: 10px;
  padding: 30px;
  color: #000 !important;
  fill: black !important;
  stroke: black !important;
}
